import React, { createContext, useState, useEffect } from "react";
import axios from "axios";
import ApiConfig from "src/ApiConfig/ApiConfig";
import { calculateTimeLeft } from "src/utils";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  // const [userData, setUserData] =useState();
  const [endTime, setEndtime] = useState();
  const [profileData, setProfileData] = useState({});

  const [timeLeft, setTimeLeft] = useState();

  const [filterData,setFilterData] = useState({
    fromDate:null,
    toDate:null
  })
// console.log("auth",userData);
  const viewUserProfile = async (token) => {
    try {
      const response = await axios({
        url: ApiConfig.adminProfile,
        method: "GET",
        headers: {
          token: token,
        },
      });
      if (response.data.responseCode === 200) {
        console.log("res",response?.data?.result)
        setProfileData(response?.data?.result);
      } 
      else if (response?.data?.responseCode === 401) {
        console.log("fdshfsfdjhfdjhfjdshfjsdhf");
      }else {
        // setUserData(null);
        console.log("err...")
      }
    } catch (error) {
      if (error?.response?.data?.responseCode === 401 && error?.response?.data?.responseMessage === "Unauthorized user.") {
        // history.push('/')
        // toast.error(error?.response?.data?.responseMessage);
        localStorage.removeItem('token')
      }
      console.log(error);
    } finally {
      // setLoading(false);
  };
  }
  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime));
        localStorage.setItem(
          "otpTimer",
          JSON.stringify(calculateTimeLeft(endTime))
        );
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  useEffect(() => {
    if (localStorage.getItem("token")) {
      viewUserProfile(localStorage.getItem("token"));
    }
  }, [localStorage.getItem("token")])

  let data = {
    userLoggedIn: isLogin,
    // userData,
    profileData,
    setEndtime,
    setTimeLeft,
    timeLeft,
    viewUserProfile: viewUserProfile,
    setFilterData:setFilterData,
    filterData,
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
